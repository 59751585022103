import {
  SET_SCAN,
  TICKET_RETURNED,
  TICKET_ERROR,
  CLEAR_SCAN,
} from '../actions/types.js';

const initialState = {
  scanned: false,
  scanned_id: '',
  loading: false,
  display_ticket: false,
  ticket_error: false,
  current_scan_result: {},
};

export default function scanner (state = initialState, action) {
  switch (action.type) {
    case SET_SCAN:
      return {
        ...state,
        scanned: true,
        scanned_id: action.payload,
        loading: true,
        display_ticket: false,
      };
    case TICKET_RETURNED:
      return {
        ...state,
        loading: false,
        display_ticket: true,
        ticket_error: false,
        current_ticket: action.payload,
      };
    case TICKET_ERROR:
      return {
        ...state,
        loading: false,
        display_ticket: false,
        ticket_error: true,
        current_ticket: {},
      };
    case CLEAR_SCAN:
      return {
        ...state,
        scanned: false,
        scanned_id: '',
        loading: false,
        display_ticket: false,
        current_ticket: {},
      };
    default:
      return state;
  }
}
