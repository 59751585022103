// Scanner
export const SET_SCAN = "SET_SCAN";
export const TICKET_RETURNED = "TICKET_RETURNED";
export const TICKET_ERROR = "TICKET_ERROR";
export const CLEAR_SCAN = "CLEAR_SCAN";

// Profile
export const START_PROFILE_SCAN = "START_PROFILE_SCAN";
export const SET_PROFILE_ID = "SET_PROFILE_ID";
export const PROFILE_RETURNED = "PROFILE_RETURNED";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";

// History
export const HISTORY_RETURNED = "HISTORY_RETURNED";

export const API_BASE = "https://bookoxex.com";
