import axios from "axios";
import { PROFILE_RETURNED, PROFILE_ERROR, API_BASE } from "./types";

export const get_profile = (profile_id) => (dispatch) => {
  const config = {
    headers: {
      "x-api-token": profile_id,
    },
  };

  axios
    .get(API_BASE + "/api/scanner/initialize", config)
    .then((res) => {
      dispatch({
        type: PROFILE_RETURNED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: PROFILE_ERROR,
        payload: null,
      });
    });
};
